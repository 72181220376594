
































import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component
export default class ConfirmDialog extends Vue {
  @Prop() private title!: string
  @Prop() private text!: string
  @Prop() private callbackFn!: () => any
  @Prop() private openDialog!: boolean

  async callback () {
    await this.callbackFn()
    this.closeDialog()
  }

  @Emit('update:dialog')
  closeDialog () {
    return false
  }
}
