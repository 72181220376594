









































import { Component, Emit, Vue } from 'vue-property-decorator'
import Item from '@/components/Item.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { getList, createItem, setListAsDone } from '@/backend'

interface ItemList {
  id: string;
  name: string
  items: object[];
}

@Component({
  components: {
    Item,
    ConfirmDialog
  }
})
export default class ListDetail extends Vue {
  list: ItemList = { id: '', name: '', items: [] }
  itemName: string = ''
  itemsDone: number = 0
  openDialog: boolean = false

  created () {
    this.getList()
  }

  toggleDialog (openDialog: boolean) {
    this.openDialog = openDialog
  }

  updateListState (done: boolean) {
    this.itemsDone = done ? this.itemsDone + 1 : this.itemsDone - 1

    if (this.itemsDone === this.list.items.length) {
      this.openDialog = true
    }
  }

  async getList () {
    this.list = await getList(this.$route.params.id)

    this.list.items.forEach((item: any) => (
      this.itemsDone = item.done ? this.itemsDone + 1 : this.itemsDone
    ))
  }

  async addItem () {
    await createItem(this.list.id, this.itemName)
    this.getList()
    this.itemName = ''
  }

  async terminarLista () {
    await setListAsDone(this.list.id)
    this.openDialog = false
    this.$router.push({ name: 'home' })
  }
}
