





























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { setItemAsDone, updateItem } from '@/backend'

@Component
export default class Item extends Vue {
  @Prop() private item!: { id: string, name: string, done: boolean }
  private beforeEditCache = ''
  private editing = false;

  async itemDone () {
    this.item.done = !this.item.done
    this.$emit('update:itemDone', this.item.done)
    return setItemAsDone(this.item.id)
  }

  editItem () {
    this.beforeEditCache = this.item.name
    this.editing = true
  }

  async doneEdit () {
    if (!this.editing) {
      return
    }

    const newName = this.item.name.trim()

    if (this.beforeEditCache !== newName) {
      await updateItem(this.item.id, newName)
    }

    this.editing = false
  }

  cancelEdit () {
    this.editing = false
    this.item.name = this.beforeEditCache
  }
}
